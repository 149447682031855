/*This is a dynamic loader for p5 scripts that fetches them from a url*/

import React from 'react'
import loadable from '@loadable/component'
import {Helmet} from "react-helmet";

const axios = require('axios');


const P5Wrapper = loadable(() => import('react-p5-wrapper'))
class P5E extends React.Component {
  constructor(props) {
    super(props);
    this.state= {script:[]}
  }
  async componentDidMount() {
    const script= await import(/* webpackIgnore: true */this.props.src)
    this.setState({script:script})
  }


  render() {
    console.log(this.props.test)
    return (
      <div>
        <Helmet>
          <style type="text/css">{`
            .p5Canvas {
              width: 80% !important;
              height: 80% !important;
            }
        `}</style>
        </Helmet>
        <P5Wrapper sketch={this.state.script.default}/>
      </div>
      );
  }
}
export default P5E;
